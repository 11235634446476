import React from 'react';
import PropTypes from 'prop-types';
import EventLayout from '../components/layouts/EventLayout';
import { graphql, Link } from 'gatsby';
import { ChevronLeftIcon } from '@heroicons/react/solid';
import SearchEngineOptimisation from '../components/SearchEngineOptimisation';
import EventDetailBanner from '../components/EventDetailBanner';
import EventSlider from '../components/EventSlider';

const EventDetail = ({ data }) => {
	const { 
		title,
		youtubeVideoID,
		youtubeTitle, 
		heading,
		byline,
		intro
	} = data.markdownRemark.frontmatter;
	
	return (
		<EventLayout>
			<SearchEngineOptimisation title={`Tobias Events - ${title}`} />
			<main id="content" role="main">
				<div className="isolate bg-black border-t-8 border-yellow">
					<div className="container w-full h-auto relative overflow-hidden">						
            <EventDetailBanner 
							youtubeVideoID={youtubeVideoID} 
							youtubeTitle={youtubeTitle} />
          </div>
				</div>

				<div className="isolate bg-white lg:pt-12 md:pt-8 pt-6 border-t-8 border-yellow">
					<div className="container">
						<div className="grid grid-cols-12 gap-6">
							<div className="md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-9 col-span-12">
								<Link to="/inspiration" className="md:-ml-12 -ml-8 uppercase text-gray-500 font-bold text-sm pb-1 underline ">
								<ChevronLeftIcon className="md:w-12 md:h-12 h-8 w-8 inline md:pr-1 pr-0 text-gray-900" />Inspiration
								</Link>
							</div>
						</div>
					</div>
				</div>

				<div className="isolate bg-white lg:pt-12 md:pt-8 pt-6">
					<div className="container">
						<div className="grid grid-cols-12 gap-6">
							<div className="md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-9 col-span-12">
								<h1 className="uppercase lg:text-5xl md:text-4xl text-3xl text-center font-bold">{ heading }</h1>
							</div>
						</div>
					</div>
				</div>



				<div className="isolate bg-white lg:pt-12 md:pt-8 pt-6">
					<div className="container">
						<div className="grid grid-cols-12 gap-6">
							<div className="md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-9 col-span-12">
								<h2 className="lg:text-2xl md:text-xl text-lg text-center">{ title }</h2>
							</div>
						</div>
					</div>
				</div>
				
				<div className="isolate bg-white lg:pt-12 md:pt-8 pt-6">
					<div className="container">
						<div className="grid grid-cols-12 gap-6">
							<div className="md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-9 col-span-12">
            		<hr className="border-yellow" />
							</div>
						</div>
          </div>
				</div>

				<div className="isolate bg-white lg:pt-12 md:pt-8 pt-6">
					<div className="container">
						<div className="grid grid-cols-12 gap-6">
							<div className="md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-9 col-span-12">
								<h2 className="uppercase lg:text-5xl md:text-4xl text-3xl text-center font-bold">{ byline }</h2>
							</div>
						</div>
					</div>
				</div>

				<div className="isolate bg-white lg:pt-12 md:pt-8 pt-6">
					<div className="container">
						<div className="grid grid-cols-12 gap-6">
							<div className="md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-9 col-span-12">
								<p className="md:text-2xl sm:text-xl text-lg font-light">{ intro }</p>
							</div>
						</div>
					</div>
				</div>				

				<div className="isolate bg-white lg:pt-12 md:pt-8 pt-6">
					<div className="container">
						<div className="grid grid-cols-12 gap-6">
							<div className="md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-9 col-span-12">
            		<hr className="border-yellow" />
							</div>
						</div>
          </div>
				</div>

				<div className="isolate bg-white lg:pt-12 md:pt-8 pt-6">
					<EventSlider />
				</div>


			</main>
		</EventLayout>
	)
}

export default EventDetail;

EventDetail.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export const eventDetailPageQuery = graphql`
query eventDetailPage($slug: String) {
  markdownRemark(frontmatter: {slug: {eq: $slug}}) {
    frontmatter {
      title
			youtubeVideoID
			youtubeTitle
      heading
			byline
			intro
    }
  }
}
`