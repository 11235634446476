import React from 'react';
import Header from '../global/Header';
import Footer from '../global/Footer';
import Store from '../../utilities/store';

const EventLayout = ({children}) => {
	return (
		<Store>
			<div className="layout">
				<Header />
					{ children  }
				<Footer />
			</div>
		</Store>
	)
}

export default EventLayout;