import React from 'react';
import Video from './Video';

const EventDetailBanner = ({youtubeVideoID, youtubeTitle}) => {
	return (
		<div className="h-full">
			<Video 
				videoSrcURL={`https://www.youtube.com/embed/${youtubeVideoID}`} 
				videoTitle={ youtubeTitle } />
		</div>
	)
}

export default EventDetailBanner;
