import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';


const EventCard = ({image, heading, youtubeLength, indexPageByLine, indexPageBlurb, slug}) => {

	const passedImg = image.substring((image.lastIndexOf('/')+1)) || null;

	let imgObj;

	const eventCardsData = useStaticQuery(graphql`
	query eventCardsData {
		allFile(filter: {sourceInstanceName: {eq: "eventThmbs"}}) {
			edges {
				node {
					childImageSharp {
						gatsbyImageData(
							width: 800, 
							placeholder: BLURRED, 
							formats: [AUTO, WEBP, AVIF]
						)
					}
					relativePath
				}
			}
		}
	}
  `);

	for (let i = 0; i < eventCardsData.allFile.edges.length; i++) {
		if (eventCardsData.allFile.edges[i].node.relativePath === passedImg) {
			imgObj = eventCardsData.allFile.edges[i].node;
			break;
		}
	}

	const backgroundImg = getImage(imgObj);
	const bgImage = convertToBgImage(backgroundImg);

	return (
		<div className="xl:mx-12 lg:mx-8 sm:mx-4 mx-2 pb-8 h-auto">
			<div className="shadow-xl">
				<Link to={`/events/${slug}`}>
					<BackgroundImage className={`relative bg-top bg-center bg-cover bg-top overflow-hidden flex align-center justify-center xl:h-60 h-44`} Tag="div" {...bgImage}>
						<div className="grid grid-col-1">
						<img src="/images/icons/icon-play-button.svg" className="h-14 w-14 m-auto" alt="Click to go to article with video" />
						<span className="absolute bottom-2 right-2 bg-black text-white w-auto px-2">{youtubeLength}</span>
						</div>
					</BackgroundImage>
					<div className="bg-white w-full h-auto">
						<div className="p-4">
							<h3 className="sm:text-base text-sm font-semibold">{heading}</h3>
							<p className="sm:text-base text-sm truncate">{indexPageByLine}</p>
							<hr className="border-yellow my-2" />
							<p className="sm:text-base text-sm">
							{indexPageBlurb}
							</p>
						</div>
					</div>
				</Link>
			</div>
		</div>
	)
}

export default EventCard;