import React, { useRef } from 'react';
import Slider from 'react-slick';
import EventCard from './EventCard';
import { useStaticQuery, graphql } from 'gatsby';
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/solid';



const EventSlider = () => {

	const path = typeof window !== 'undefined' ? window.location.href : '';
	const lastPos = path.lastIndexOf('/');
	let slug = path.substring(lastPos + 1);
	// This next bit of code handles instances where last char in url is '/' and will need to go from the second to last '/'
	const alteredPath = path.substring(0,lastPos);
	const lastPos2 = alteredPath.lastIndexOf('/');
	if (slug === '') {
		slug = path.substring(lastPos2+1, lastPos);
	}

	const eventSliderData = useStaticQuery(graphql`
	query eventSliderData {
		allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "EventDetail"}}}) {
			edges {
				node {
					id
					frontmatter {
						slug
						youtubeLength
						indexPage {
							indexPageBlurb
							indexPageByLine
							indexPageHeading
							indexPageImage
						}
					}
				}
			}
		}
	}
  `);

	

	const PreviousArrow = () => {
		return (
			<button onClick={() => eventSliderRef.current.slickPrev()} onKeyDown={() => eventSliderRef.current.slickPrev()} className="focus:outline-none focus:ring-4 focus:ring-yellow cursor-pointer rounded-full h-18 w-18 flex items-center justify-center bg-gray-500 bg-opacity-80 absolute md:left-10 left-5 top-1/4 z-10">
				<ChevronLeftIcon className="h-12 w-12 text-yellow" />
			</button>
		)
	}
	
	const NextArrow = () => {
		return (
			<button onClick={() => eventSliderRef.current.slickNext()} onKeyDown={() => eventSliderRef.current.slickNext()} className="focus:outline-none focus:ring-4 focus:ring-yellow cursor-pointer rounded-full h-18 w-18 flex items-center justify-center bg-gray-500 bg-opacity-80 absolute md:right-10 right-5 top-1/4 z-10">
				<ChevronRightIcon className="h-12 w-12 text-yellow" />
			</button>
		)
	}
	
	const eventSliderRef = useRef();

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		initialSlide: 0,
		centerMode: true,
		prevArrow: <PreviousArrow />,
		nextArrow: <NextArrow />,
		appendDots: dots => (
			<div>
				<ul> {dots} </ul>
			</div>
		),
		customPaging: i => (
			<div className="h-6 w-6 inline md:m-6 m-4 cursor-pointer rounded-full inline-block float-left">
				{i + 1}
			</div>),

		responsive: [
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 0
				}
			},

			{
				breakpoint: 748,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					initialSlide: 0
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					initialSlide: 0
				}
			}
		]
	};
	return (
			<Slider className="yellow" {...settings} ref={eventSliderRef}>
			{
			eventSliderData.allMarkdownRemark.edges.map((event, i) => {
				if (event.node.frontmatter.indexPage.indexPageImage && event.node.frontmatter.slug !== slug) {
					return (
						<EventCard key={`eventCard-${i}`} 
						image={event.node.frontmatter.indexPage.indexPageImage} 
						heading={event.node.frontmatter.indexPage.indexPageHeading}
						youtubeLength={event.node.frontmatter.youtubeLength} 
						indexPageByLine={event.node.frontmatter.indexPage.indexPageByLine} 
						indexPageBlurb={event.node.frontmatter.indexPage.indexPageBlurb} 
						slug={event.node.frontmatter.slug} />
					)
				}
				else {
					return null;
				}
			})}
			</Slider>
	);
}

export default EventSlider;